<template>
  <svg
    class="logo-svg"
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 2069 517.58"
  >
    <g class="tp-letters">
      <path
        fill="var(--logo-teal)"
        d="M1627.92 96.27c-7.93 0-18.6 5.49-25.92 11.28-3.05-3.35-15.25-17.38-41.48-17.38-39.34 0-56.42 24.4-56.42 44.53 0 25.92 14.64 38.73 26.23 45.44v.61c-9.15 4.27-26.23 14.64-26.23 31.11 0 9.76 7.32 19.52 20.74 22.57v1.83c-14.64 6.1-31.72 17.69-31.72 34.16 0 9.76 4.27 17.69 15.25 24.09 3.34 1.9 7.06 3.43 10.98 4.68 11.85 3.75 25.6 4.78 36.6 4.78 7.77.17 15.01-.44 21.62-1.55 17.77-3 31.05-9.75 38.16-15.53 13.42-10.37 18.91-21.96 18.91-35.38 0-9.15-3.05-20.74-15.25-29.89-13.42-10.98-28.67-12.81-51.85-11.59-26.84 1.22-38.43 1.22-38.43-10.98 0-7.32 7.62-12.81 13.11-15.25 6.41 1.22 10.67 1.22 17.38 1.22 14.33 0 28.97-3.66 38.73-14.03 9.15-9.76 12.81-21.96 12.81-33.85 0-9.76-1.83-16.47-3.66-20.13 2.44-1.83 5.49-1.83 7.32-1.83 2.44 0 4.88 1.83 7.32 3.66 1.83 1.83 4.88 4.27 9.46 4.27 6.71 0 12.2-6.1 12.2-12.2.61-7.93-4.57-14.64-15.86-14.64zm-89.06 143.05 27.45-1.83c29.28-1.22 42.09 9.76 42.09 27.45.6 17.72-24.8 26.85-40.73 27.13-.25 0-.51.01-.75.01-17.69 0-26.23-1.83-35.68-7.32-5.79-3.35-10.98-12.5-10.98-21.04 0-5.86 2.33-11.16 5.69-15.36 3.64-4.53 8.48-7.78 12.91-9.04zm20.74-64.97c-8.54 0-13.11-2.44-17.38-6.71-5.18-4.58-7.62-14.33-7.62-24.09 0-11.89 1.83-25.92 8.54-34.46 4.58-5.49 10.07-7.62 16.47-7.62 7.32 0 11.59 2.75 14.33 6.1 3.05 3.97 6.71 11.9 6.71 24.09 0 21.65-5.19 42.69-21.05 42.69z"
      />
      <path
        fill="var(--logo-green)"
        d="M866.98 258.22c15.29 0 36.55 1.49 54.46 13.43 20.89 13.8 30.96 37.67 30.96 60.43 0 14.55-3.73 36.93-24.25 54.46-19.77 16.79-42.52 19.4-60.8 19.4h-17.16v101.08h-38.05v-248.8h54.84zm-16.79 112.65h17.16c32.82 0 48.49-16.04 48.49-39.17 0-13.8-5.59-38.42-48.86-38.42h-16.79v77.59zM1089.28 446.96h-106.3l-27.23 60.05h-41.03l123.84-263.34 117.87 263.34h-41.03l-26.12-60.05zm-15.29-35.81-36.93-84.3-38.42 84.3h75.35zM1219.46 258.22c30.21 0 49.61 3.73 65.65 14.55 27.23 18.28 29.47 47.74 29.47 58.56 0 35.81-22.01 61.92-55.2 69.38l77.58 106.3h-45.88l-71.24-101.83h-6.71v101.83h-38.05V258.22h44.38zm-6.35 114.14h11.94c10.44 0 52.97-1.12 52.97-40.66 0-35.06-33.2-38.42-51.85-38.42h-13.06v79.08zM1489.86 294.03h-96.22v63.04h93.23v35.81h-93.23v78.33h96.22v35.81H1355.6v-248.8h134.26v35.81z"
      />
      <path
        fill="var(--logo-teal)"
        d="M793.45 110.61c-2.44 0-4.27-1.22-4.27-4.27 0-2.44 1.83-4.27 5.79-5.79 8.54-3.05 35.07-25.62 41.78-38.43 1.22-2.44 3.05-3.05 4.88-3.05 2.44 0 3.66 1.83 3.66 4.27l-1.22 30.19h40.26c1.83 0 2.44 1.22 2.14 2.44l-3.05 12.81c-.31 1.22-1.22 1.83-3.05 1.83h-36.9c-.61 12.81-.92 27.45-.92 40.87v27.45c0 23.79 10.06 28.67 16.16 28.67 6.71 0 13.42-1.53 17.69-3.66 2.14-.92 3.05-.92 3.66.61l1.83 3.66c.61 1.83 0 3.05-1.22 4.27-4.27 3.05-17.08 12.2-38.43 12.2-18.3 0-32.02-14.64-32.02-27.45 0-10.98.92-35.38.92-46.97v-39.65h-17.69zM964.56 206.07c-6.71 7.32-14.64 12.5-26.54 16.47-7.01 2.13-13.42 2.13-19.52 2.13-11.28 0-21.04-9.15-21.04-26.23 0-14.03 6.1-27.75 28.06-37.51 18.91-9.15 30.5-14.03 37.21-18.91v-15.86c-.61-6.1-1.22-13.42-4.88-17.08-3.05-3.35-8.54-4.58-15.25-4.58-5.49 0-12.81 1.83-17.69 5.49-4.27 3.05-1.22 7.62-1.22 14.94 0 10.98-5.49 17.69-18.3 17.69-5.49 0-10.37-3.66-10.37-10.37 0-8.54 4.88-18.3 14.03-25.01 12.81-9.76 29.28-17.08 49.41-17.08 17.08 0 22.57 3.66 28.06 8.54 6.71 6.1 7.32 14.64 7.32 22.87l.61 48.19v14.64c0 16.47 2.44 21.35 7.93 21.35 4.27 0 8.54-2.44 9.76-4.88 1.22-1.83 1.83-1.83 3.05-1.22l3.66 2.44c1.22.61 1.83 1.83.61 4.88-3.05 5.49-10.37 17.69-28.06 17.69-11.59 0-21.35-5.49-25.62-18.6h-1.22zm-1.83-52.76c-13.42 8.54-33.55 13.72-33.55 35.99 0 12.5 6.71 18.6 14.03 18.6 8.54-.61 19.52-9.15 19.52-14.64v-39.95z"
      />
      <path
        fill="var(--logo-teal)"
        d="M1038.98 48.7c-.61-10.37-5.79-15.25-15.55-18.3l-6.1-1.83c-1.83-.61-1.83-1.22-1.83-2.44v-3.66c0-.61.61-1.83 2.44-2.44 12.2-3.66 35.99-11.59 45.75-17.69 3.66-1.22 5.49-1.83 6.71-1.83 1.83 0 3.05 1.22 3.05 5.49-.61 16.47-1.22 79.91-1.22 96.68v43.92c0 14.03 0 45.14.61 54.29.61 5.49 1.22 9.76 7.93 9.76h11.59c3.05 0 3.66.61 3.66 2.74v5.49c0 1.83-.61 3.05-1.83 3.05-2.44 0-15.86-.92-37.21-.92-23.18 0-35.38.92-37.82.92-1.83 0-2.44-1.22-2.44-3.05v-5.79c0-1.83.61-2.44 4.27-2.44h11.59c3.05 0 7.62-2.44 7.62-10.98V104.2c0-21.35-.61-47.58-1.22-55.5zM1151.21 203.33c0 4.88 2.44 7.32 6.1 7.32h6.71c2.44 0 3.05.61 3.05 2.74v6.1c0 1.52-.61 2.44-2.44 2.44s-12.2-.92-29.28-.92c-19.52 0-29.89.92-31.41.92-1.53 0-2.14-1.22-2.14-3.05v-5.79c0-1.83.61-2.44 3.05-2.44h7.93c4.27 0 6.1-3.66 6.1-7.32.61-6.1.92-32.94.92-45.14l-.61-110.1c0-11.59-.92-17.69-16.16-21.96-3.05-1.22-4.27-1.83-4.27-2.44v-4.27c0-1.22 1.22-2.44 4.27-3.05 8.54-1.83 34.16-11.59 38.43-12.81 4.27-1.83 7.32-3.05 9.15-3.05 2.44 0 2.75 1.22 2.44 4.88-1.22 14.64-1.83 59.78-1.83 96.07v46.36c3.66 0 9.15-1.22 12.2-3.66 7.32-6.71 24.4-25.31 28.06-30.8 1.22-1.83 2.14-3.96 2.14-5.49 0-1.83-2.14-3.96-5.79-3.96h-2.75c-1.53 0-2.13-.61-2.13-2.44v-6.4c0-1.22.61-2.44 2.44-2.44s12.81.92 30.5.92c16.77 0 24.09-.92 26.54-.92 1.52 0 2.13 1.22 2.13 3.35v5.49c0 1.83-.61 2.44-2.44 2.44h-3.05c-4.27 0-12.2 1.52-18.91 5.19-6.71 3.66-31.11 25.92-39.65 33.85 9.15 10.37 52.46 58.56 59.78 63.44 4.88 3.05 12.2 4.27 15.86 4.27 1.83 0 2.44.61 2.44 2.74v6.1c0 1.83-.61 2.44-3.05 2.44s-10.98-.92-31.11-.92c-17.69 0-30.5.92-32.94.92-1.83 0-2.44-.61-2.44-3.05v-5.19c0-1.83.61-2.44 2.44-2.44h2.44c2.44 0 3.66-1.83 3.05-4.27-.61-3.05-29.28-37.21-35.99-45.14-3.05-3.05-7.32-3.66-9.76-3.66v45.14z"
      />
      <path
        fill="var(--logo-teal)"
        d="M1274.13 134.09c0-9.15-1.83-15.25-15.86-18.91-2.75-.61-3.05-1.52-3.05-2.75v-3.35c0-1.22.61-1.83 2.14-2.44 4.27-1.83 34.46-12.5 39.65-14.64 3.05-1.22 5.49-1.83 7.32-1.83s2.75 1.22 2.44 3.66c-.61 4.88-.61 24.4-.61 49.71v32.94c0 11.59 0 22.57.61 26.23.61 5.49 1.83 7.93 4.88 7.93h12.2c2.44 0 3.05.61 3.05 2.13v6.1c0 1.83-.61 3.05-2.44 3.05-2.44 0-16.47-.92-36.9-.92-17.69 0-29.28.92-32.94.92-1.53 0-2.44-.61-2.44-3.05v-5.79c0-1.83 1.22-2.44 3.05-2.44h13.42c3.05 0 4.58-1.22 4.88-6.1.31-9.15.61-18.91.61-27.45v-43zm10.37-74.72c-9.76 0-19.52-7.62-19.52-18.3 0-9.15 8.85-18.91 21.35-18.91 12.81 0 18.91 10.37 18.91 17.69 0 9.15-6.41 19.52-20.74 19.52zM1374.17 92.61c2.44-1.22 4.27-2.44 6.1-2.44s3.05 1.22 3.66 3.66l2.44 15.86h1.83c3.66-4.27 9.15-9.15 15.25-12.2 7.32-4.27 15.25-7.32 24.4-7.32 10.37 0 20.13 1.83 26.84 7.32 13.42 9.76 16.77 18.91 16.77 31.41v43.92c0 9.76-.3 23.18-.3 29.28 0 6.71 3.66 8.54 7.32 8.54h8.54c2.75 0 3.66.61 3.66 2.13v7.01c0 1.53-.61 2.14-2.44 2.14-2.44 0-11.59-.92-32.94-.92-18.3 0-28.06.92-29.89.92s-2.44-1.22-2.44-3.66v-5.18c0-1.83.61-2.44 3.66-2.44h6.1c2.44 0 4.88-1.83 5.49-7.32.92-7.93 1.22-16.47 1.22-28.67v-35.07c0-12.2-.61-20.13-7.93-26.23-6.1-5.49-11.59-5.49-18.3-5.49-4.88 0-12.2 1.22-19.52 7.93-4.88 4.88-6.41 13.42-6.41 21.65v62.83c0 6.71 1.53 10.37 5.79 10.37h5.49c2.44 0 3.05.61 3.05 2.74v5.49c0 1.83-.61 3.05-2.44 3.05s-10.37-.92-28.67-.92c-18.91 0-28.67.92-30.5.92-1.22 0-1.83-1.22-1.83-3.05v-5.79c0-1.83.61-2.44 3.05-2.44h6.1c3.66 0 7.93-1.22 7.93-6.1v-68.62c0-9.15-5.49-14.03-12.81-17.69l-2.44-1.22c-1.83-.61-2.44-1.83-2.44-3.05v-2.44c0-1.22 1.22-2.44 3.05-3.05l33.56-15.86z"
      />
      <path
        fill="var(--logo-green)"
        d="M1699.88 258.22v172.33l-122.32-121.13c-6.61 1.12-13.84 1.72-21.62 1.55-11 0-24.75-1.03-36.6-4.78v200.82h38.05V328.2l180.53 189.11V258.22h-38.04zM1849.57 294.03v212.98h-38.05V294.03h-57.07v-35.81h152.18v35.81h-57.06zM2031.98 313.43c-2.24-5.6-5.6-10.82-11.19-15.67-7.83-6.34-16.04-8.95-26.86-8.95-23.13 0-35.06 13.8-35.06 29.84 0 7.46 2.61 20.52 26.86 30.21l24.99 10.07c45.88 18.65 58.56 44.76 58.56 73.11 0 47.37-33.57 79.82-80.2 79.82-28.72 0-45.88-10.82-58.56-24.99-13.43-14.92-19.4-31.33-20.89-48.49l37.67-8.21c0 12.31 4.48 23.87 10.44 31.71 7.09 8.95 17.53 14.92 32.45 14.92 23.13 0 41.03-16.79 41.03-41.78 0-25.36-19.4-35.81-35.81-42.52l-23.87-10.07c-20.51-8.58-50.73-25.74-50.73-63.04 0-33.57 26.11-65.65 72.74-65.65 26.86 0 42.15 10.07 49.98 16.78 6.71 5.97 13.8 14.55 19.02 24.99l-30.57 17.92z"
      />
    </g>
    <path
      fill="var(--logo-green)"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M458.48 406.25c-4.31-17.25-33.42-2.16-57.14 9.7-40.97 20.48-85.17 31.27-131.53 32.34-115.36 0-218.86-69-263.06-175.73-2.16-3.23-6.47-5.39-6.47-2.16l1.08 3.23C44.49 398.7 163.08 482.79 295.68 482.79c42.05 0 84.09-8.62 122.9-25.87 8.63-3.24 47.44-21.56 39.9-50.67"
    />
    <path
      fill="var(--logo-green)"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M738.79 406.33c-9.7 14.02-30.19-9.7-47.44-26.95-29.11-31.27-64.69-54.98-103.5-71.15-100.26-37.74-213.46-11.86-287.85 65.76-2.16 2.16-6.47 2.16-5.39-1.08l2.16-2.16c79.78-94.87 210.23-129.37 325.59-85.17 36.66 14.02 71.15 35.58 99.19 62.53 6.45 6.47 33.41 35.58 17.24 58.22"
    />
    <path
      fill="var(--logo-teal)"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M329.11 296.37c3.23 0 6.47 1.08 9.7 1.08 7.55-5.39 16.17-10.78 24.8-15.09-6.47-1.08-12.94-2.16-19.41-3.23-69-6.47-134.76 31.27-162.79 93.8l-1.08 2.16c0 1.08 2.16 1.08 3.23 0 28.03-53.93 85.17-84.11 145.55-78.72"
    />
    <path
      fill="var(--logo-green)"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M501.6 36.54c51.75 0 93.8 46.36 93.8 103.5s-42.05 103.5-93.8 103.5-93.8-46.36-93.8-103.5 42.05-103.5 93.8-103.5"
    />
    <path
      fill="var(--logo-green)"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M112.41 113.09c51.75 0 93.8 46.36 93.8 103.5s-42.05 103.5-93.8 103.5-93.8-46.36-93.8-103.5 42.05-103.5 93.8-103.5"
    />
    <path
      fill="var(--logo-teal)"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M306.47 134.65c30.19-1.08 54.98 25.87 56.06 59.3s-23.72 61.45-53.91 61.45c-30.19 1.08-54.98-25.87-56.06-59.3-1.08-33.42 23.72-60.37 53.91-61.45"
    />
  </svg>
</template>

<style lang="scss" scoped>
.logo-svg {
  --logo-green: light-dark(#014847, #ffffff);
  --logo-teal: light-dark(#29b99a, #ffffff);
}
.logo-svg {
  --logo-green: #014847;
  --logo-teal: #29b99a;

  @media(width >= 48em){
    --logo-green: #fff;
    --logo-teal: #fff;
  }
}
</style>