import constants from '@/exports/constants'
import type {
  ICard,
  ICardAlert,
  IField,
  IGroupedCards,
  ITab,
  ITemplateCard
} from '../interfaces'

export const Tab: ITab = {
  itemID: 0,
  name: null,
  userID: 0,
  caseID: 0,
  createdWhen: null,
  updatedWhen: null
}

export const Card: ICard = {
  itemID: 0,
  tabID: 0,
  tabName: '',
  shared: false,
  title: '',
  description: '',
  fields: [],
  userID: 0,
  caseID: 0,
  sharedUserID: 0,
  createdWhen: null,
  updatedWhen: null,
  viewedWhen: null
}

export const Field: IField = {
  itemID: 0,
  cardID: 0,
  content: '',
  type: 0
}

export const EmptyCard: ITemplateCard = {
  templateName: 'infoLibrary.templates[0].templateName',
  templateDescription: 'infoLibrary.templates[0].templateDescription',
  description: 'infoLibrary.templates[0].description',
  nameField: 'infoLibrary.templates[0].nameField',
  phoneField: 'infoLibrary.templates[0].phoneField',
  emailField: 'infoLibrary.templates[0].emailField',
  addressField: 'infoLibrary.templates[0].addressField',
  cityField: 'infoLibrary.templates[0].cityField',
  stateField: 'infoLibrary.templates[0].stateField',
  zipField: 'infoLibrary.templates[0].zipField'
}


export const NameField = {
  ...Field,
  Type: constants.infoLibraryFieldTypes.NAME
}

export const PhoneField = {
  ...Field,
  Type: constants.infoLibraryFieldTypes.PHONE
}

export const EmailField = {
  ...Field,
  Type: constants.infoLibraryFieldTypes.EMAIL
}

export const AddressField = {
  ...Field,
  Type: constants.infoLibraryFieldTypes.ADDRESS
}

export const CityField = {
  ...Field,
  Type: constants.infoLibraryFieldTypes.CITY
}

export const StateField = {
  ...Field,
  Type: constants.infoLibraryFieldTypes.STATE
}

export const ZipField = {
  ...Field,
  Type: constants.infoLibraryFieldTypes.ZIP
}

export interface IInfoLibraryState {
  tabs: ITab[]
  selectedTabID: number | null
  showAddTab: boolean
  addTabErrorMessage: string | null
  showEditTab: boolean
  tabForEdit: ITab
  editTabErrorMessage: string | null
  showDeleteTab: boolean
  deleteTabErrorMessage: string | null
  selectedCardID: number | null
  showAddCard: boolean
  addCardErrorMessage: string | null
  showEditCard: boolean
  cardForEdit: ICard
  editCardErrorMessage: string | null
  showDeleteCard: boolean
  showUpgradePrompt: boolean
  deleteCardErrorMessage: string | null
  cards: ICard[]
  groupedCards: IGroupedCards[]
  searchTerm: string | null
  filterCardsBy: string
  sortCardsBy: string
  tabCards: ICard[]
  placeholderCards: ICard[]
  currentTemplate: ITemplateCard | null
}
