<!-- <script setup>
import { ref, watch } from 'vue';

// Ref to store the selected theme
const selectedTheme = ref('system');

// Watch for changes in selectedTheme to update the HTML attribute
watch(selectedTheme, (newTheme) => {
  const html = document.documentElement; // Get the <html> element

  if (newTheme === 'system') {
    html.removeAttribute('color-scheme');
  } else {
    html.setAttribute('color-scheme', newTheme);
  }
});
</script>

<template>
  <div>
    <label for="light">
      <input type="radio" id="light" value="light" name="theme" v-model="selectedTheme" />
      <span class="sr-only">Light</span>
      <img src="" alt="light theme">
    </label>
    <label>
      <input type="radio" id="dark" value="dark" name="theme" v-model="selectedTheme" /> 
      <span class="sr-only">Dark</span>
      <img src="" alt="Dark theme">
    </label>
    <label>
      <input type="radio" id="system" value="system" name="theme" v-model="selectedTheme" />
      <span class="sr-only">System</span>
      <img src="" alt="System theme">
    </label>
  </div>
</template>

<style scoped>
.root {
  margin-left: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.btn-container {
  gap: 0.5rem;
}

.color-cycle {
  display: inline-flex;
  border-radius: 3rem;
  width: 82px;
  border: 0;
  padding: 0.25rem;
  background-color: var(--surface-3);
  box-shadow: var(--inner-shadow-2);
}

/* Theme Toggle */
.nav-collapse .color-cycle {
  display: inline-block;
  padding: 0.5rem;
  width: 55px;
}

.icon-container {
  display: flex;
  background-color: var(--surface-2);
  border-radius: 50%;
  padding: 0.25rem;
  transform: translate3d(0, 0, 0) !important;
  transition: 0.5s var(--ease-elastic-3) !important;
  box-shadow: var(--shadow-1);
}

.nav-collapse .icon-container {
  transition: none !important;
}

@media (prefers-color-scheme: dark) {
  .icon-container {
    transform: translate3d(130%, 0, 0);
    transition: 0.5s var(--ease-elastic-3) !important;
  }
  .nav-collapse .icon-container {
    transform: translate3d(0, 0, 0);
    transition: none !important;
  }
}

[color-scheme='light'] {
  .icon-container {
    display: flex;
    background-color: white;
    border-radius: 50%;
    transform: translate3d(0, 0, 0) !important;
    transition: 0.5s var(--ease-elastic-1) !important;
  }
  .nav-collapse .icon-container {
    transition: none !important;
  }
}

[color-scheme='dark'] {
  .icon-container {
    transform: translate3d(130%, 0, 0) !important;
    transition: 0.5s var(--ease-elastic-3) !important;
  }
  .nav-collapse .icon-container {
    transform: translate3d(0, 0, 0) !important;
    transition: none !important;
  }
}

.nav-collapse :is(.label-light, .label-dark) {
  display: none;
}

.sun-and-moon {
  --icon-fill: var(--brand-7);
  --icon-fill-hover: var(--brand-6);
  stroke-linecap: round;

  filter: none;
}
.sun-and-moon > :is(.moon, .sun, .sun-beams) {
  transform-origin: center;
}

.sun-and-moon > :is(.moon, .sun) {
  fill: var(--icon-fill);
  stroke: none;
}

.theme-toggle:is(:hover, :focus-visible) > .sun-and-moon > :is(.moon, .sun) {
  fill: var(--icon-fill-hover);
}

.sun-and-moon > .sun-beams {
  stroke: var(--icon-fill);
  stroke-width: 2px;
}

.theme-toggle:is(:hover, :focus-visible) .sun-and-moon > .sun-beams {
  stroke: var(--icon-fill-hover);
}

[color-scheme='dark'] .sun-and-moon > .sun {
  transform: scale(1.75);
}

[color-scheme='dark'] .sun-and-moon > .sun-beams {
  opacity: 0;
}

[color-scheme='dark'] .sun-and-moon > .moon > circle {
  transform: translateX(-7px) !important;
}

@supports (cx: 1) {
  [color-scheme='dark'] .sun-and-moon > .moon > circle {
    cx: 17;
    transform: translateX(0) !important;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .sun-and-moon > .sun {
    transition: transform 0.5s var(--ease-elastic-3) !important;
  }

  .sun-and-moon > .sun-beams {
    transition:
      transform 0.5s var(--ease-elastic-4),
      opacity 0.5s var(--ease-3) !important;
  }

  .sun-and-moon .moon > circle {
    transition: transform 0.25s var(--ease-out-5) !important;
  }

  @supports (cx: 1) {
    .sun-and-moon .moon > circle {
      transition: cx 0.25s var(--ease-out-5) !important;
    }
  }

  [color-scheme='dark'] .sun-and-moon > .sun {
    transition-timing-function: var(--ease-3) !important;
    transition-duration: 0.25s !important;
    transform: scale(1.75) !important;
  }

  [color-scheme='dark'] .sun-and-moon > .sun-beams {
    transition-duration: 0.15s !important;
    transform: rotateZ(-25deg) !important;
  }

  [color-scheme='dark'] .sun-and-moon > .moon > circle {
    transition-duration: 0.5s !important;
    transition-delay: 0.25s !important;
  }
}

.scheme-state {
  display: none;
  text-transform: capitalize;
  margin-top: 0.25rem;
}
.scheme-directions {
  margin-top: 0.5rem;
}

.nav-collapse .scheme-directions {
  display: none;
}
.nav-collapse .scheme-state {
  display: inline-block;
  margin-left: -0.5rem;
}
</style> -->
<template>
  <div class="theme-switcher">
    <label for="light">
      <input
        class="sr-only"
        type="radio"
        id="light"
        value="light"
        name="theme"
        v-model="store"
      />
      <img src="/theme-light-icon.svg" alt="light theme" />
      <span>Light</span>
    </label>
    <label>
      <input type="radio" id="dark" value="dark" name="theme" v-model="store" />
      <img src="/theme-dark-icon.svg" alt="Dark theme" />
      <span>Dark</span>
    </label>
    <label>
      <input type="radio" id="auto" value="auto" name="theme" v-model="store" />
      <img src="/theme-system-icon.svg" alt="System theme" />
      <span>System default</span>
    </label>
  </div>
</template>

<script setup>
import { useColorMode } from '@vueuse/core'

// colormode
const { store } = useColorMode({ attribute: 'color-scheme' })
</script>

<style scoped>
.theme-switcher {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

input[type='radio'] {
  opacity: 0;
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: clip;
}

input[type='radio']:checked + img {
  outline: 0.25rem solid var(--brand);
  outline-offset: -2px;
  border-radius: 1rem;
}

label {
  width: calc(100% / 2 - 1rem);
}

@media (width >= 31.25rem) {
  label {
    width: calc(100% / 3 - 1rem);
  }
}

img {
  filter: none;
}

span {
  font-size: var(--font-size-1);
}
</style>
