<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <h2 class="sr-only">Appearance</h2>

  <ColorModeCycle />

</template>

<script setup lang="ts">
import ColorModeCycle from '@/components/ColorModeCycle.vue'
import { useCommonStore } from '@/stores/CommonStore'
import { onBeforeMount } from 'vue'
import constants from '@/exports/constants'

const commonStore = useCommonStore()
const { setPageHeaders, setLayoutOptions } = commonStore

onBeforeMount(() => {
  setPageHeaders({
    page: 'Account Settings',
    pageName: 'Account Settings',
    pageNameMobile: 'Account Settings',
    pageIcon: 'fa-light fa-user-gear',
    searchEnabled: false,
    pageData: {},
    searching: false
  })
  setLayoutOptions({
    pageType: constants.pageType.list,
    headerText: 'Account Settings',
    subHeaderText: '',
    showBackbutton: false,
    showUpgradeBanner: false,
    showAdsNotInList: false,
    showAddNewButton: false
  })
})
</script>
